import '~/assets/styles/index.scss';
import '@popperjs/core/dist/umd/popper.min';
import 'bootstrap/dist/js/bootstrap.min';

import React from 'react';
import { GeoLocationProvider } from '~/app/context';

export const wrapRootElement = ({ element }) => {
  return <GeoLocationProvider>{element}</GeoLocationProvider>;
};
